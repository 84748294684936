import React, { useState } from 'react'
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import FrontPage from './pages/FrontPage';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Privacy from "./pages/Privacy";
import Services from './pages/Services';

import './App.scss';
import Consent from './pages/Consent';
import Contacts from './pages/Contacts';
import Partners from './pages/Partners';
import Preloader from './components/utilities/Preloader/Preloader';
import BtnTop from './components/utilities/BtnTop/BtnTop';
import ProductCallPage from './pages/product/ProductCallPage';
import ProductTargetSegments from './pages/product/ProductTargetSegments';
import ProductCallCenter from './pages/product/ProductCallCenter';
import ProductDataEnrichment from './pages/product/ProductDataEnrichment';
import Custom404 from './pages/404';

// function ScrollToTop() {
//     const { pathname } = useLocation();

//     useEffect(() => {
//         window.scrollTo({
//             top: 0,
//             left: 0,
//             behavior: 'instant'
//         })
//     }, [pathname]);

//     return null;
// }

function App() {
    const [activeMenu, setActiveMenu] = useState(false)
    const [overflowActiveMenu, setOverflowActiveMenu] = useState(false)

    return (
        <div className={`App ${activeMenu ? 'active' : ''} ${overflowActiveMenu ? 'overflow' : ''}`}>
            <Router>
                {/* <ScrollToTop /> */}
                <Header activeMenu={setActiveMenu} overflowActiveMenu={setOverflowActiveMenu} />
                <main>
                    <Preloader>
                        <Route path="/" element={<FrontPage />} />
                        <Route path="/privacy" element={<Privacy />} />
                        <Route path="/services" element={<Services />} />
                        <Route path="/consent" element={<Consent />} />
                        <Route path="/contacts" element={<Contacts />} />
                        <Route path="/partners" element={<Partners />} />
                        <Route path="/product/retargeting-to-contact-call" element={<ProductCallPage />} />
                        <Route path="/product/target-segments" element={<ProductTargetSegments />} />
                        <Route path="/product/call-center-telephony" element={<ProductCallCenter />} />
                        <Route path="/product/data-enrichment" element={<ProductDataEnrichment />} />
                        <Route path="*" element={<Custom404 />} />
                    </Preloader>
                </main>
                <Footer />
                <BtnTop />
            </Router>
        </div>
    );
}

export default App;
