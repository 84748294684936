import CustomText from "../../utilities/CustomText/CustomText";
import SectionHeadTitlte from "../../utilities/SectionHead/SectionHeadTitlte";
import './ProductPageWhyNeed.scss';
import { useMediaQuery } from 'react-responsive'

function ProductPageWhyNeed({ content }) {
   const isTablet = useMediaQuery({ query: '(max-width: 1199px)' });
   return (
      <section className="product_page_need">
         <div className="row">
            {isTablet &&
               <div className="col-12">
                  <div className="product_page_need_head">
                     <div className="product_page_need_left-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                           <path d="M29.1211 36.6639C29.099 36.5532 29.0879 36.4426 29.0879 36.3319C29.0879 36.1992 29.0879 36.0775 29.0879 35.9668C29.0879 35.148 29.232 34.4398 29.5202 33.8423C29.8084 33.2448 30.1853 32.7026 30.6508 32.2158C31.1164 31.7068 31.6041 31.231 32.114 30.7884C32.6461 30.3237 33.156 29.8368 33.6437 29.3278C33.9319 29.018 34.1869 28.686 34.4086 28.332C34.6302 27.9557 34.7965 27.5685 34.9074 27.1701C35.0404 26.7718 35.1069 26.3956 35.1069 26.0415C35.1069 25.2891 34.9406 24.6584 34.6081 24.1494C34.2755 23.6404 33.8211 23.2642 33.2447 23.0207C32.6683 22.7552 31.9921 22.6224 31.2162 22.6224C30.6176 22.6224 30.0079 22.7109 29.3872 22.888C28.7886 23.0429 28.2676 23.3195 27.8242 23.7178C27.3808 24.0941 27.0816 24.6141 26.9264 25.278H24C24.1108 24.3928 24.3769 23.6183 24.7981 22.9544C25.2415 22.2905 25.7957 21.7483 26.4608 21.3278C27.1259 20.8852 27.8575 20.5532 28.6556 20.3319C29.4759 20.1106 30.3183 20 31.1829 20C32.3579 20 33.4663 20.2102 34.5083 20.6307C35.5503 21.029 36.3927 21.6598 37.0356 22.5228C37.6785 23.3859 38 24.5145 38 25.9087C38 26.5947 37.9113 27.2365 37.734 27.834C37.5566 28.4094 37.2906 28.9627 36.9359 29.4938C36.6033 30.0028 36.2043 30.5007 35.7387 30.9876C35.1623 31.6072 34.5859 32.1715 34.0095 32.6805C33.4331 33.1674 32.9565 33.6985 32.5796 34.2739C32.2027 34.8271 32.0143 35.5353 32.0143 36.3983C32.0143 36.4426 32.0143 36.4869 32.0143 36.5311C32.0143 36.5754 32.0143 36.6196 32.0143 36.6639H29.1211ZM30.5178 44C29.9414 44 29.4648 43.8119 29.0879 43.4357C28.7332 43.0595 28.5558 42.5837 28.5558 42.0083C28.5558 41.4329 28.7332 40.9571 29.0879 40.5809C29.4648 40.2047 29.9414 40.0166 30.5178 40.0166C31.0942 40.0166 31.5709 40.2047 31.9477 40.5809C32.3246 40.9571 32.5131 41.4329 32.5131 42.0083C32.5131 42.5837 32.3246 43.0595 31.9477 43.4357C31.5709 43.8119 31.0942 44 30.5178 44Z" fill="#C9C9C9" />
                           <path d="M62.0001 38C62.0001 49.3136 62.0001 54.9708 58.4853 58.4853C54.9708 62.0001 49.3136 62.0001 38 62.0001" stroke="#C9C9C9" strokeWidth="3" strokeLinecap="round" />
                           <path d="M26.0001 62.0001C14.6863 62.0001 9.02948 62.0001 5.51472 58.4853C2 54.9708 2 49.3136 2 38" stroke="#C9C9C9" strokeWidth="3" strokeLinecap="round" />
                           <path d="M26.0001 2C14.6863 2 9.02948 2 5.51472 5.51472C2 9.02948 2 14.6863 2 26.0001" stroke="#C9C9C9" strokeWidth="3" strokeLinecap="round" />
                           <path d="M38 2C49.3136 2 54.9708 2 58.4853 5.51472C62.0001 9.02948 62.0001 14.6863 62.0001 26.0001" stroke="#C9C9C9" strokeWidth="3" strokeLinecap="round" />
                        </svg>
                     </div>
                     <SectionHeadTitlte className={'product_page_need_left-title'} dangerouslySetInnerHTML={{ __html: content?.title }} />
                  </div>
               </div>
            }
            <div className="col-xxl-5 col-xl-6 col-md-6">
               <div className="product_page_need_left">
                  {!isTablet &&
                     <>
                        <div className="product_page_need_left-icon">
                           <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                              <path d="M29.1211 36.6639C29.099 36.5532 29.0879 36.4426 29.0879 36.3319C29.0879 36.1992 29.0879 36.0775 29.0879 35.9668C29.0879 35.148 29.232 34.4398 29.5202 33.8423C29.8084 33.2448 30.1853 32.7026 30.6508 32.2158C31.1164 31.7068 31.6041 31.231 32.114 30.7884C32.6461 30.3237 33.156 29.8368 33.6437 29.3278C33.9319 29.018 34.1869 28.686 34.4086 28.332C34.6302 27.9557 34.7965 27.5685 34.9074 27.1701C35.0404 26.7718 35.1069 26.3956 35.1069 26.0415C35.1069 25.2891 34.9406 24.6584 34.6081 24.1494C34.2755 23.6404 33.8211 23.2642 33.2447 23.0207C32.6683 22.7552 31.9921 22.6224 31.2162 22.6224C30.6176 22.6224 30.0079 22.7109 29.3872 22.888C28.7886 23.0429 28.2676 23.3195 27.8242 23.7178C27.3808 24.0941 27.0816 24.6141 26.9264 25.278H24C24.1108 24.3928 24.3769 23.6183 24.7981 22.9544C25.2415 22.2905 25.7957 21.7483 26.4608 21.3278C27.1259 20.8852 27.8575 20.5532 28.6556 20.3319C29.4759 20.1106 30.3183 20 31.1829 20C32.3579 20 33.4663 20.2102 34.5083 20.6307C35.5503 21.029 36.3927 21.6598 37.0356 22.5228C37.6785 23.3859 38 24.5145 38 25.9087C38 26.5947 37.9113 27.2365 37.734 27.834C37.5566 28.4094 37.2906 28.9627 36.9359 29.4938C36.6033 30.0028 36.2043 30.5007 35.7387 30.9876C35.1623 31.6072 34.5859 32.1715 34.0095 32.6805C33.4331 33.1674 32.9565 33.6985 32.5796 34.2739C32.2027 34.8271 32.0143 35.5353 32.0143 36.3983C32.0143 36.4426 32.0143 36.4869 32.0143 36.5311C32.0143 36.5754 32.0143 36.6196 32.0143 36.6639H29.1211ZM30.5178 44C29.9414 44 29.4648 43.8119 29.0879 43.4357C28.7332 43.0595 28.5558 42.5837 28.5558 42.0083C28.5558 41.4329 28.7332 40.9571 29.0879 40.5809C29.4648 40.2047 29.9414 40.0166 30.5178 40.0166C31.0942 40.0166 31.5709 40.2047 31.9477 40.5809C32.3246 40.9571 32.5131 41.4329 32.5131 42.0083C32.5131 42.5837 32.3246 43.0595 31.9477 43.4357C31.5709 43.8119 31.0942 44 30.5178 44Z" fill="#C9C9C9" />
                              <path d="M62.0001 38C62.0001 49.3136 62.0001 54.9708 58.4853 58.4853C54.9708 62.0001 49.3136 62.0001 38 62.0001" stroke="#C9C9C9" strokeWidth="3" strokeLinecap="round" />
                              <path d="M26.0001 62.0001C14.6863 62.0001 9.02948 62.0001 5.51472 58.4853C2 54.9708 2 49.3136 2 38" stroke="#C9C9C9" strokeWidth="3" strokeLinecap="round" />
                              <path d="M26.0001 2C14.6863 2 9.02948 2 5.51472 5.51472C2 9.02948 2 14.6863 2 26.0001" stroke="#C9C9C9" strokeWidth="3" strokeLinecap="round" />
                              <path d="M38 2C49.3136 2 54.9708 2 58.4853 5.51472C62.0001 9.02948 62.0001 14.6863 62.0001 26.0001" stroke="#C9C9C9" strokeWidth="3" strokeLinecap="round" />
                           </svg>
                        </div>
                        <SectionHeadTitlte className={'product_page_need_left-title'} dangerouslySetInnerHTML={{ __html: content?.title }} />
                     </>
                  }
                  <CustomText className={'product_page_need_left-text'}>
                     <p dangerouslySetInnerHTML={{ __html: content?.mainText }} />
                  </CustomText>
               </div>
            </div>
            <div className="offset-xxl-2 col-xxl-5 offset-xl-0 col-xl-6 col-md-6">
               <div className="product_page_need_right">
                  <div className="product_page_need_right_content">
                     <CustomText className={'product_page_need_right_content-text small'}>
                        <p dangerouslySetInnerHTML={{ __html: content?.rightText }} />
                     </CustomText>
                     <div className="product_page_need_right_content-icon" dangerouslySetInnerHTML={{ __html: content?.rightIcon }} />
                  </div>
                  <span className="line_top-bot"></span>
                  <span className="line_right-left"></span>
               </div>
            </div>
         </div>
      </section >
   );
}

export default ProductPageWhyNeed;